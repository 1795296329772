import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { message } from 'antd';
import axios from 'axios';
import { fetchSignInMethodsForEmail, sendEmailVerification, signOut, } from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import instituteAppService from '../services/InstituteService';
import { instituteAppApiPath, miscApiPath, stripeCheckoutApiPath, userDetailApiPath, userRoleApiPath, } from '../utils/apiPaths';
import { auth, logInWithEmailAndPassword, logout, registerWithEmailAndPassword, signInWithGoogle, } from '../utils/firebase/firebaseIndex';
import { isPublicRoute, isShouldLogout } from '../utils/publicRoutes';
import { INVITE_STATES } from '../components/Navbar/Navbar';
export var AppContext = createContext({
    isExtensionInstalled: false,
    setIsExtensionInstalled: function () { return null; },
    upgrade: false,
    setUpgrade: function () { return null; },
    signUpOrSignInWithGoogleCall: function () { return null; },
    userInfo: {},
    appConfig: {},
    authToken: null,
    user: {},
    isInstituteCoach: false,
    isMobile: false,
    newUser: false,
    setNewUser: function () { return null; },
    setUserInfo: function () { return null; },
    selectedInstitute: '',
    newUserSignupComplete: false,
    loading: false,
    handleAcceptInviteByLink: function () { return null; },
    institute: null,
    getUserDetails: function () { return null; },
    signinWithEmailAndPassword: function (email, password) { return null; },
    whiteLabeledInstitute: '',
    signupWithEmailAndPassword: function (email, password, fname, lname, src, setLoading, cb) { return null; },
});
export var sendTokenToChromeExtension = function (_a) {
    var user = _a.user;
    return new Promise(function (resolve, reject) {
        var _a, _b;
        (_b = (_a = window.chrome) === null || _a === void 0 ? void 0 : _a.runtime) === null || _b === void 0 ? void 0 : _b.sendMessage(process.env.REACT_APP_EXTENSION_ID, { user: user }, function (response) {
            var _a, _b;
            var lastError = (_b = (_a = window.chrome) === null || _a === void 0 ? void 0 : _a.runtime) === null || _b === void 0 ? void 0 : _b.lastError;
            if (response && !response.success) {
                reject(lastError);
                return response;
            }
            else {
                resolve('Done');
            }
        });
    });
};
function UserProvider(_a) {
    var _this = this;
    var children = _a.children;
    var _b = useIdToken(auth), user = _b[0], loading = _b[1];
    var _c = useState(false), upgrade = _c[0], setUpgrade = _c[1];
    var _d = useState(), institute = _d[0], setInstitute = _d[1];
    var _e = useState(false), isInstituteCoach = _e[0], setIsInstituteCoach = _e[1];
    var _f = useState(window.innerWidth <= 768), isMobile = _f[0], setIsMobile = _f[1];
    var _g = useState(false), newUserSignupComplete = _g[0], setNewUserSignupComplete = _g[1];
    var _h = useState(false), newUser = _h[0], setNewUser = _h[1];
    var _j = useState(''), selectedInstitute = _j[0], setSelectedInstitute = _j[1];
    var _k = useState(null), authToken = _k[0], setAuthToken = _k[1];
    var _l = useState({}), appConfig = _l[0], setAppConfig = _l[1];
    var _m = useState(false), isExtensionInstalled = _m[0], setIsExtensionInstalled = _m[1];
    var _o = useState(''), whiteLabeledInstitute = _o[0], setWhiteLabeledInstitute = _o[1];
    var _p = useState({
        fname: '',
        lname: '',
        email: '',
        username: '',
        profilePic: '',
        jobBoardOnboarding: null,
        questionnaire: {},
        removedSkills: [],
        addedSkills: [],
        phoneNo: '',
        gender: '',
        dob: '',
        invites: {},
        userType: '',
        country: '',
        webAppLanguage: '',
        userId: '',
    }), userInfo = _p[0], setUserInfo = _p[1];
    var navigate = useNavigate();
    var i18n = useTranslation().i18n;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        var selectedInstitute = localStorage.getItem('selectedInstitute');
        if (!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.certificates))
            return; // to check if we have loaded the user details or not
        if (!selectedInstitute ||
            !(((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites) === null || _a === void 0 ? void 0 : _a[selectedInstitute]) ||
                ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles) === null || _b === void 0 ? void 0 : _b[selectedInstitute]))) {
            var validInviteOrg = Object.keys((_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites) !== null && _c !== void 0 ? _c : {}).find(function (key) { var _a; return ((_a = ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites) || {})) === null || _a === void 0 ? void 0 : _a[key]) !== INVITE_STATES.deactivated; });
            if (validInviteOrg) {
                selectedInstitute = validInviteOrg;
            }
            else if (Object.keys((_d = userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles) !== null && _d !== void 0 ? _d : {}).length) {
                selectedInstitute = (_e = Object.keys(userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles)) === null || _e === void 0 ? void 0 : _e[0];
            }
            else if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.userDetailLoaded) && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userType)) {
                selectedInstitute = "";
            }
        }
        if (selectedInstitute) {
            localStorage.setItem('selectedInstitute', selectedInstitute);
            setSelectedInstitute(selectedInstitute);
            axios
                .get("".concat(instituteAppApiPath, "/").concat(selectedInstitute))
                .then(function (res) {
                setInstitute(__assign({ id: selectedInstitute }, res.data));
            });
            if ((_f = userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles) === null || _f === void 0 ? void 0 : _f[selectedInstitute])
                setIsInstituteCoach(true);
        }
        else {
            localStorage.setItem('selectedInstitute', '');
        }
    }, [
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.certificates,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.userDetailLoaded,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.userType,
    ]);
    useEffect(function () {
        if ('webAppLanguage' in userInfo) {
            i18n.changeLanguage(userInfo === null || userInfo === void 0 ? void 0 : userInfo.webAppLanguage);
        }
    }, [i18n, userInfo]);
    function handleWindowSizeChange() {
        if (window.innerWidth <= 768)
            setIsMobile(true);
        else
            setIsMobile(false);
    }
    useEffect(function () {
        var _a, _b;
        var siteHost = window.location.host;
        var isWhiteLabeledInstitute = !!(((_a = siteHost === null || siteHost === void 0 ? void 0 : siteHost.split('.')) === null || _a === void 0 ? void 0 : _a[1]) !== 'careerflow');
        if (isWhiteLabeledInstitute)
            setWhiteLabeledInstitute((_b = siteHost === null || siteHost === void 0 ? void 0 : siteHost.split('.')) === null || _b === void 0 ? void 0 : _b[1]);
        if (isWhiteLabeledInstitute) {
            instituteAppService
                .getInstituteFromWhiteLabeledUrl(siteHost)
                .then(function (res) {
                setInstitute(res.data);
            })
                .catch(function (e) {
                console.log('error');
                console.log(e);
            });
        }
        axios.get("".concat(miscApiPath, "/webappConfig")).then(function (res) {
            if (res.data.content)
                setAppConfig(res.data.content);
        });
        window.addEventListener('resize', handleWindowSizeChange);
        return function () {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    var invitedBy = localStorage.getItem('invitedBy');
    var goToCheckout = localStorage.getItem('referalId') && localStorage.getItem('priceId');
    useEffect(function () {
        if (goToCheckout && userInfo.email) {
            message.success('Please wait till we take you to subscription checkout page!');
            axios
                .post(stripeCheckoutApiPath, {
                price: localStorage.getItem('priceId'),
                client_reference_id: localStorage.getItem('referalId') || undefined,
                success_url: 'https://careerflow.ai/thank-you-premium',
                coupon: localStorage.getItem('coupon') || undefined,
            })
                .then(function (res) {
                window.location.assign(res.data.url);
                localStorage.removeItem('priceId');
            })
                .catch(function (e) {
                message.error('There was an error in initiating the transaction');
                console.error(e);
            })
                .finally(function () { });
        }
    }, [goToCheckout, userInfo.email]);
    useEffect(function () {
        if (isPublicRoute())
            return;
        if ((!user || (!(user === null || user === void 0 ? void 0 : user.emailVerified) && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1694802600000)) &&
            !loading &&
            !window.location.href.includes('/extension/login')) {
            navigate('/login');
        }
        else if (userInfo.userDetailLoaded &&
            !userInfo.initialOnboardingDone &&
            (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1703010600000) {
            console.log(window.location.pathname);
            if (window.location.pathname !== '/plan')
                navigate("/walkthrough");
        }
        else if (window.location.pathname === '/onboarding' &&
            (userInfo.jobBoardOnboarding === 10 ||
                userInfo.jobBoardOnboarding === true)) {
            navigate('/');
        }
        else if ((window.location.pathname === '/login' ||
            window.location.href.includes('/extension/login')) &&
            !!user &&
            ((user === null || user === void 0 ? void 0 : user.emailVerified) || (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) < 1694802600000)) {
            sendTokenToChromeExtension({
                user: user,
            })
                .then(function () {
                message.success('Careerflow chrome extension also logged in. Please feel free to close this window.');
                if (window.location.href.includes('/extension/login'))
                    window.close();
            })
                .catch(function (e) { return console.log(e); });
            navigate('/');
        }
    }, [
        userInfo.jobBoardOnboarding,
        user,
        loading,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.created,
        navigate,
        userInfo,
    ]);
    useEffect(function () {
        if (!user && !loading && isShouldLogout())
            logout();
    }, [loading, user]);
    useEffect(function () {
        var _a, _b;
        if ((!!user && newUser === false) ||
            (!!user && newUser && newUserSignupComplete)) {
            try {
                var jwt = (_b = (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.stsTokenManager) === null || _b === void 0 ? void 0 : _b.accessToken;
                var decodedToken_1 = jwt_decode(jwt);
                axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
                    localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
                    setUserInfo(function (userInfo) {
                        return __assign(__assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email, userType: userRoleGetResponse.data.premiumUser ||
                                decodedToken_1.userType === 'premium'
                                ? 'premium'
                                : 'basic' }), userRoleGetResponse.data), { userId: userRoleGetResponse.data && userRoleGetResponse.data.id });
                    });
                });
                axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
                    setUserInfo(function (userInfo) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, removedSkills: ((_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.removedSkills) || [], addedSkills: ((_f = userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.addedSkills) || [], country: (_g = userDetailGetResponse.data[0]) === null || _g === void 0 ? void 0 : _g.country, webAppLanguage: (_h = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _h === void 0 ? void 0 : _h.webAppLanguage }), (_j = userDetailGetResponse.data) === null || _j === void 0 ? void 0 : _j[0]);
                    });
                });
            }
            catch (err) {
                console.log('err :>> ', err);
            }
        }
    }, [newUser, newUserSignupComplete, user]);
    useEffect(function () {
        if (userInfo.jobBoardOnboarding === 10) {
            try {
                axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
                    localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
                    setUserInfo(function (userInfo) {
                        return __assign(__assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email }), userRoleGetResponse.data), { userId: userRoleGetResponse.data && userRoleGetResponse.data.id });
                    });
                });
                axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
                    setUserInfo(function (userInfo) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, country: (_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.country, webAppLanguage: (_f = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.webAppLanguage }), (_g = userDetailGetResponse.data) === null || _g === void 0 ? void 0 : _g[0]);
                    });
                });
            }
            catch (err) {
                console.log('err :>> ', err);
            }
        }
    }, [userInfo.jobBoardOnboarding]);
    var getUserDetails = useCallback(function () {
        var _a, _b;
        var jwt = (_b = (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.stsTokenManager) === null || _b === void 0 ? void 0 : _b.accessToken;
        var decodedToken = jwt_decode(jwt);
        axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
            localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
            setUserInfo(function (userInfo) {
                return __assign(__assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email }), userRoleGetResponse.data), { userId: userRoleGetResponse.data && userRoleGetResponse.data.id, userType: userRoleGetResponse.data.premiumUser ||
                        decodedToken.userType === 'premium'
                        ? 'premium'
                        : 'basic' });
            });
        });
        axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
            setUserInfo(function (userInfo) {
                var _a, _b, _c, _d, _e, _f, _g;
                return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, country: (_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.country, webAppLanguage: (_f = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.webAppLanguage }), (_g = userDetailGetResponse.data) === null || _g === void 0 ? void 0 : _g[0]);
            });
        });
    }, []);
    var handleAcceptInviteByLink = useCallback(function () {
        var invitedBy = localStorage.getItem('invitedBy');
        if (invitedBy) {
            var payload = {
                firstName: userInfo.fname,
                lastName: userInfo.lname,
                email: userInfo.email,
                status: 'active',
            };
            axios
                .post("".concat(instituteAppApiPath, "/").concat(invitedBy, "/linkInvite"), payload)
                .then(function () {
                localStorage.removeItem('invitedBy');
                getUserDetails();
            });
        }
    }, [getUserDetails, userInfo.email, userInfo.fname, userInfo.lname]);
    useEffect(function () {
        if (invitedBy && userInfo.email) {
            handleAcceptInviteByLink();
        }
    }, [handleAcceptInviteByLink, invitedBy, userInfo.email]);
    var signup = function (authResponse, src) {
        if (src === void 0) { src = 'webapp'; }
        return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // eslint-disable-next-line no-async-promise-executor
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var displayName, numberOfWords, firstname, lastname, userRoleRequestBody, inviterOrg, e_1;
                        var _a, _b, _c, _d, _e;
                        return __generator(this, function (_f) {
                            switch (_f.label) {
                                case 0:
                                    _f.trys.push([0, 2, , 3]);
                                    displayName = authResponse.user.displayName;
                                    numberOfWords = ((_a = displayName === null || displayName === void 0 ? void 0 : displayName.split(' ')) === null || _a === void 0 ? void 0 : _a.length) || 0;
                                    firstname = numberOfWords > 1
                                        ? (_c = (_b = displayName === null || displayName === void 0 ? void 0 : displayName.split(' ')) === null || _b === void 0 ? void 0 : _b.slice(0, -1)) === null || _c === void 0 ? void 0 : _c.join(' ')
                                        : displayName || '';
                                    lastname = numberOfWords > 1 ? (_e = (_d = displayName === null || displayName === void 0 ? void 0 : displayName.split(' ')) === null || _d === void 0 ? void 0 : _d.slice(-1)) === null || _e === void 0 ? void 0 : _e[0] : '';
                                    userRoleRequestBody = {
                                        role: 'MENTEE',
                                        fname: firstname,
                                        lname: lastname,
                                        mentorStatus: '',
                                        profilePhoto: authResponse.user.photoURL || '',
                                        linkedin: '',
                                        signupSource: src,
                                        mentorRating: 0,
                                    };
                                    inviterOrg = localStorage.getItem('invitedBy');
                                    return [4 /*yield*/, axios.post(userRoleApiPath, __assign(__assign({}, userRoleRequestBody), { inviterOrg: inviterOrg }))];
                                case 1:
                                    _f.sent();
                                    localStorage.removeItem('invitedBy');
                                    localStorage.setItem('token', authResponse._tokenResponse.idToken);
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _f.sent();
                                    console.log(e_1);
                                    reject(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    var signUpOrSignInWithGoogleCall = function (src) {
        if (src === void 0) { src = 'webapp'; }
        return __awaiter(_this, void 0, void 0, function () {
            var authResponse, user_1, email, e_2, isCoach, e_3, _a, isNewUser_1, idToken, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setNewUser(true);
                        analyticsEvent('Sign in with google', 'Login');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 16, , 17]);
                        return [4 /*yield*/, signInWithGoogle()];
                    case 2:
                        authResponse = _b.sent();
                        user_1 = authResponse.user;
                        email = user_1.email;
                        if (!(whiteLabeledInstitute && (institute === null || institute === void 0 ? void 0 : institute.allowOnlyInvitedSignups))) return [3 /*break*/, 12];
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 12]);
                        return [4 /*yield*/, instituteAppService.checkIfStudentInvited(institute === null || institute === void 0 ? void 0 : institute.id, email)];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 12];
                    case 5:
                        e_2 = _b.sent();
                        isCoach = false;
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, instituteAppService.verifyInstituteCoach(institute === null || institute === void 0 ? void 0 : institute.id, email)];
                    case 7:
                        _b.sent();
                        isCoach = true;
                        return [3 /*break*/, 9];
                    case 8:
                        e_3 = _b.sent();
                        console.error(e_3);
                        return [3 /*break*/, 9];
                    case 9:
                        if (!!isCoach) return [3 /*break*/, 11];
                        message.error("You do not have permission to log in or an unknown error occurred. Please contact ".concat((institute === null || institute === void 0 ? void 0 : institute.name) || (institute === null || institute === void 0 ? void 0 : institute.id), " admin."));
                        return [4 /*yield*/, signOut(auth)];
                    case 10: return [2 /*return*/, _b.sent()];
                    case 11: return [3 /*break*/, 12];
                    case 12:
                        _a = authResponse._tokenResponse, isNewUser_1 = _a.isNewUser, idToken = _a.idToken;
                        setAuthToken(idToken);
                        if (!isNewUser_1) return [3 /*break*/, 14];
                        setNewUser(true);
                        analyticsEvent('New User Sign In', 'SignIn');
                        return [4 /*yield*/, signup(authResponse, src)];
                    case 13:
                        _b.sent();
                        setNewUserSignupComplete(true);
                        getUserDetails();
                        return [3 /*break*/, 15];
                    case 14:
                        setNewUser(false);
                        _b.label = 15;
                    case 15:
                        localStorage.setItem('token', authResponse._tokenResponse.idToken);
                        sendTokenToChromeExtension({
                            jwt: idToken,
                            user: user_1,
                        })
                            .then(function () {
                            message.success('Careerflow chrome extension also logged in.');
                            if (!isNewUser_1)
                                window.close(); // Don't close window if the new user has signed up
                        })
                            .catch(function (err) { return console.log(err); });
                        if (newUser) {
                            return [2 /*return*/];
                        }
                        getUserDetails();
                        return [3 /*break*/, 17];
                    case 16:
                        error_1 = _b.sent();
                        console.log;
                        return [3 /*break*/, 17];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    var signupWithEmailAndPassword = function (email, password, fname, lname, src, setLoading, callback) { return __awaiter(_this, void 0, void 0, function () {
        var authResponse, userRoleRequestBody, inviterOrg, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setNewUser(true);
                    return [4 /*yield*/, registerWithEmailAndPassword(email, password)];
                case 1:
                    authResponse = _b.sent();
                    sendEmailVerification(authResponse.user).catch(function (err) {
                        return console.log('err', err);
                    });
                    userRoleRequestBody = {
                        role: 'MENTEE',
                        fname: fname,
                        lname: lname,
                        mentorStatus: '',
                        profilePhoto: '',
                        linkedin: '',
                        signupSource: src,
                        mentorRating: 0,
                    };
                    inviterOrg = localStorage.getItem('invitedBy');
                    return [4 /*yield*/, axios.post(userRoleApiPath, __assign(__assign({}, userRoleRequestBody), { inviterOrg: inviterOrg }))];
                case 2:
                    _b.sent();
                    localStorage.removeItem('invitedBy');
                    setNewUserSignupComplete(true);
                    getUserDetails();
                    if (!((_a = authResponse.user) === null || _a === void 0 ? void 0 : _a.emailVerified)) {
                        sendEmailVerification(authResponse === null || authResponse === void 0 ? void 0 : authResponse.user).then(function () {
                            if (callback)
                                callback();
                        });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    message.error("".concat(error_2).replace('FirebaseError: Firebase:', ''));
                    navigate('/login');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var signinWithEmailAndPassword = function (email, password, cb) { return __awaiter(_this, void 0, void 0, function () {
        var result, authResponse, token, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, fetchSignInMethodsForEmail(auth, email)];
                case 1:
                    result = _c.sent();
                    // Handle case where user already signed in with Google previously, so they have auth but no password.
                    // If we don't do this, user will receive the "user not found" error message which is misleading.
                    if (result.includes('google.com') && !result.includes('password')) {
                        message.error('You previously signed in using "Continue With Google". To sign in via email, please create a password using the "Forgot Password" option.');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, logInWithEmailAndPassword(email, password)];
                case 2:
                    authResponse = _c.sent();
                    if (!((_a = authResponse.user) === null || _a === void 0 ? void 0 : _a.emailVerified) &&
                        (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1694802600000) {
                        cb();
                    }
                    if (((_b = authResponse.user) === null || _b === void 0 ? void 0 : _b.emailVerified) || (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) < 1694802600000)
                        sendTokenToChromeExtension({
                            user: authResponse.user,
                        })
                            .then(function () {
                            message.success('Careerflow chrome extension also logged in.');
                            window.close();
                        })
                            .catch(function (err) { return console.log(err); });
                    return [4 /*yield*/, authResponse.user.getIdToken()];
                case 3:
                    token = _c.sent();
                    localStorage.setItem('token', token);
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _c.sent();
                    console.log("".concat(error_3));
                    if ("".concat(error_3).includes('user-not-found')) {
                        message.error('User not found! Please use the Sign Up option to create a new account.');
                    }
                    else {
                        message.error("".concat(error_3).replace('FirebaseError: Firebase:', ''));
                    }
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(AppContext.Provider, __assign({ value: {
            upgrade: upgrade,
            selectedInstitute: selectedInstitute,
            user: user,
            setUpgrade: setUpgrade,
            signUpOrSignInWithGoogleCall: signUpOrSignInWithGoogleCall,
            userInfo: userInfo,
            authToken: authToken,
            newUser: newUser,
            appConfig: appConfig,
            newUserSignupComplete: newUserSignupComplete,
            institute: institute,
            setNewUser: setNewUser,
            isMobile: isMobile,
            setUserInfo: setUserInfo,
            isInstituteCoach: isInstituteCoach,
            isExtensionInstalled: isExtensionInstalled,
            setIsExtensionInstalled: setIsExtensionInstalled,
            loading: loading,
            handleAcceptInviteByLink: handleAcceptInviteByLink,
            getUserDetails: getUserDetails,
            signupWithEmailAndPassword: signupWithEmailAndPassword,
            signinWithEmailAndPassword: signinWithEmailAndPassword,
            whiteLabeledInstitute: whiteLabeledInstitute,
        } }, { children: children })));
}
export default UserProvider;
